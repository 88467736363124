<script lang="ts" setup>
import type { BlokColumns } from '~~/types/storyblok/bloks'
const props = defineProps<{ blok: BlokColumns | undefined }>()

const columns = computed(() => {
  return props.blok?.columns || []
})

const gridClass = computed(() => {
  const count = props.blok?.columns?.length || 1
  return count === 2
    ? 'grid-12 cols-2'
    : count === 3
    ? 'grid-12 cols-3'
    : 'grid-12 cols-1'
})

const verticalAlignment = computed(() => {
  return props.blok?.vertical_alignment === 'end'
    ? 'items-end'
    : props.blok?.vertical_alignment === 'start'
    ? 'items-start'
    : 'items-center'
})
</script>

<template lang="pug">
div(
  v-editable="blok"
  class="blok-columns container grid grid-cols-1 md:grid-cols-12 md:gap-36 xl:gap-48"
  :class="[gridClass, verticalAlignment]"
)
  StoryblokComponent(
    v-for="column in columns"
    :key="column._uid"
    :blok="{ ...column }"
    class="column"
  )
</template>

<style lang="postcss" scoped>
.blok-columns {
  & + & {
    @apply mt-72;
  }
}
.grid-12 {
  &.cols-1 {
    .column {
      @apply md:col-span-12;
    }
  }
  &.cols-2 {
    .column {
      @apply md:col-span-6 xl:col-span-5;
    }

    > :first-child {
      @apply xl:col-start-2;
    }
  }

  &.cols-3 {
    .column {
      @apply md:col-span-4;
    }
  }
}
</style>
